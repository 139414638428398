
















































































import OrgBanner from '../components/OrgBanner.vue'
import EventsDisplay from '../components/EventsDisplay.vue'
import Vue from "vue";
import { Watch, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  components: {
    OrgBanner,
    EventsDisplay
  },
  computed: {
    ...mapState([
      'activeTab',
      'searchResultsOrgs',
      'searchResultsEvents'
    ])
  }
})
export default class Search extends Vue {

  loading = true

  searchText = ''

  private async mounted () {
    this.doSearch()
  }

  @Watch('$route.query.s')
  private async doSearch () {
    const query = this.$route.query
    if (typeof query.s === 'string' && query.s.length > 0) {
      this.loading = true
      this.searchText = query.s
      await this.$store.dispatch('textSearch', this.searchText)
      this.loading = false
    }
  }

}
